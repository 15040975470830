.App {
  font-family: sans-serif;
  text-align: center;
}

svg {
  overflow: visible;
}

svg .st0 {
  fill: #ff0000;
}
svg .st1 {
  fill: #f0ff00;
}
